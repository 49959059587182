import store from "../store";
import router from "../router";
import {Actions} from "../store/enums/StoreEnums";

// probably token time out, redirect to login page.
export default (axios) => {
  axios.interceptors.response.use(response => {
      return response;
  }, error => {
      let currentPathObject = router.currentRoute.value;
      if (error.response.status === 401
          // todo: middleware kullanılması daha uygun olur.
          // eğer middleware guest değilse yazılabilir.
          && currentPathObject.name  !== "sign-in"
          && currentPathObject.name  !== "sign-up"
          && currentPathObject.name  !== "logout"
          && currentPathObject.name  !== "password-reset"
      ) {
           store.dispatch(Actions.PURGE_AUTH).then(() => router.replace('/sign-in'))
      }
      return Promise.reject(error);
  });
}