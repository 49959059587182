import {createStore} from "vuex";
import {config} from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import ApiModule from "@/store/modules/ApiModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import EarningsModule from "@/store/modules/EarningsModule";
import SearchModule from "@/store/modules/SearchModule";
import DateFilterModule from "@/store/modules/DateFilterModule";
import GeneralSettingsModule from "@/store/modules/GeneralSettingsModule";
import ParametersModule from "@/store/modules/ParametersModule";
import LangModule from "@/store/modules/LangModule";

config.rawError = true;

const store = createStore({
    state: {
        points: [],
        PersonalData:{}
    },
    mutations:{
        setPersonalData(state,data){
            state.PersonalData = data
        }
    },
    modules: {
        ApiModule,
        AuthModule,
        BodyModule,
        BreadcrumbsModule,
        ConfigModule,
        EarningsModule,
        SearchModule,
        DateFilterModule,
        GeneralSettingsModule,
        ParametersModule,
        LangModule,
    },
});

export default store;
