import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {api_url} from "@/ayar";

export interface Parameter {
  value: string;
}

export interface ParametersInterface {
  parameters: Array<string>;
}

@Module
export default class ParametersModule extends VuexModule  {
  parameters = [];

  get getParameterList() {
    return this.parameters;
  }

  @Mutation
  [Mutations.SET_PARAMETERS_LIST](parameter) {
    this.parameters = parameter;
  }

  @Action
  [Actions.SET_PARAMETERS_LIST_ACTION](data) {
    return new Promise<void>((resolve) => {
      ApiService.post(api_url+'parameters', data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PARAMETERS_LIST, data);
          resolve();
        })
    });
  }

}
