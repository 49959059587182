export default function getPlatform(default_base_url: string | null = null ) {
    let hostname = ""
    switch (window.location.hostname) {
        case "localhost":
            hostname = default_base_url ?? "https://heristedigin.test/"
            break;
        default:
            hostname = window.location.origin.replace('backoffice.','') + "/"
            break;
    }
    return hostname
}