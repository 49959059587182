import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import VueRouteMiddleware from 'vue-route-middleware';
import auth from '@/middleware/auth'
import guest from '@/middleware/guest'
import JwtService from "@/core/services/JwtService";
import {base_url} from "@/ayar";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/ozet",
        name: "ozet",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Ozet.vue"),
      },
      {
        path: "/products",
        name: "Products",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Products.vue"),
      },
      {
        path: "/packet_groups",
        name: "PacketGroups",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/PacketGroups.vue"),
      },
      {
        path: "/messages",
        name: "Mesajlar",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Destek/Mesajlar.vue"),
      },
      {
        path: "/complaint",
        name: "Şikayet Talepleri",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Destek/Sikayet.vue"),
      },
      {
        path: "/ticket",
        name: "Destek Talepleri",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Destek/Destek.vue"),
      },
      {
        path: "/my-orders",
        name: "Siparislerim",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Siparisler/MyOrders.vue"),
      },
      {
        path: "/customer-orders",
        name: "CustomerOrders",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Siparisler/CustomerOrders.vue"),
      },
      {
        path: "/order/:id",
        name: "OrderDetail",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/GelirYonetimi/Kazanclar.vue"),
        props: true,
      },
      {
        path: "/dealer-orders",
        name: "Müşterileri Siparişleri",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Siparisler/DealerOrders.vue"),
      },
      {
        path: "/my-customers",
        name: "Müşterilerim",
        component: () => import("@/views/v1/Musteriler/Musteriler.vue"),
      },
      {
        path: "/potential-customers",
        name: "Müşteri Adayları",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Musteriler/MusteriAdaylarim.vue"),
      },
      {
        path: "/my-dealer",
        name: "Bayilerim",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Bayiler/Bayilerim.vue"),
      },
      {
        path: "/potential-dealer",
        name: "Bayi Adaylarım",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Bayiler/BayiAdaylarim.vue"),
      },
      {
        path: "/add-new-dealer",
        name: "YeniBayiKaydi",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Bayiler/YeniBayiKaydi.vue"),
      },
      {
        path: "/earnings",
        name: "Gelirler",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/GelirYonetimi/Kazanclar.vue"),
      },
      {
        path: "/earnings/:key",
        name: "Earnings",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/GelirYonetimi/Kazanclar.vue"),
        props: true
      },
      {
        path: "/points",
        name: "Puanlar",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/GelirYonetimi/Puanlar.vue"),
      },
      {
        path: "/bonus",
        name: "Bonuslar",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/GelirYonetimi/Bonuslar.vue"),
      }, 
      { 
        path: "/files",
        name: "Belgeler",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/GelirYonetimi/Belgeler.vue"),
      },
      {
        path: "/career",
        name: "Kariyer",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/KariyerIslemleri/Kariyer.vue"),
      }, 
      {
        path: "/career_indicator",
        name: "Kariyer Göstergesi",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/KariyerIslemleri/KariyerGostergesi.vue"),
      }, 
      { 
        path: "/career_volume",
        name: "Kariyer Hacmi",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/KariyerIslemleri/KariyerHacmi.vue"),
      },
      {
        path: "/network_tree/:id?",
        name: "Network Ağacım",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/AgacYonetimi/NetworkAgacim.vue"),
      }, 
      {
        path: "/unilevel_tree/:id?",
        name: "Unilevel Ağacım",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/AgacYonetimi/UnilevelAgacim.vue"),
      }, 
      { 
        path: "/waiting_room",
        name: "Yerleşim Bekleyen Bayiler",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/AgacYonetimi/YerlesimBekleyenBayiler.vue"),
      },
      { 
        path: "/detailsOrders/:id",
        name: "DetailsOrders",
        meta: {
            middleware: auth
        },
        component: () => import("../components/backoffice/details-page/DetailsOrders.vue"),
        props: true
      },
      {
        path: "/detailsDealers/:id",
        name: "DetailsDealers",
        meta: {
            middleware: auth
        },
       component: () => import("../components/backoffice/details-page/DetailsDealers.vue"),
        props: true,
        children: [
          {
            alias: '',
            path: "/v_dealerProfile/:id",
            name: "DetailsProfile",
            meta: {
                middleware: auth
            },
            component: () =>
              import("../components/backoffice/details-page/DetailsProfile.vue"),
              props: true,
          },
          {
            path: "/v_dealersDetailsOrders/:id",
            name: "OrderDetailsProfile",
            meta: {
                middleware: auth
            },
            component: () =>
              import("@/views/v1/Bayiler/BayiDetay/BayiDetaySiparis.vue"),
              props: true,
          },
          {
            path: "/v_dealersDetailsEarnings/:id",
            name: "EarningsDetailsProfile",
            meta: {
                middleware: auth
            },
            component: () =>
              import("@/views/v1/Bayiler/BayiDetay/BayiDetayOdemeler.vue"),
              props: true,
          },
          {
            path: "/v_dealersDetailsReferences/:id",
            name: "MusteriveBayileri",
            meta: {
                middleware: auth
            },
            component: () =>
              import("@/views/v1/Bayiler/BayiDetay/MusteriveBayileri.vue"),
              props: true,
          },
        ],
      },
      { 
        path: "/pageDetails/:id",
        name: "PageDetails",
        meta: {
            middleware: auth
        },
        component: () => import("../components/backoffice/details-page/PageDetails.vue"),
        props: true
      },
      { 
        path: "/sozlesmeler",
        name: "Sozlesmeler",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Sozlesmeler.vue"),
        props: true
      },
      {
        path: "/bayi-haritasi",
        name: "Map",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/Bayiler/Map.vue"),
        props: true
      },
      {
        path: "/carts",
        name: "Carts",
        meta: {
          middleware: auth
        },
        component: () => import("../views/v1/Carts.vue"),
        props: true
      },
      {
        path: "/checkout",
        name: "Checkout",
        meta: {
          middleware: auth
        },
        component: () => import("../views/v1/Checkout.vue"),
        props: true
      },
      {
        path: "/earning/description",
        name: "EarningDescription",
        meta: {
            middleware: auth
        },
        component: () => import("@/views/v1/EarningDescription.vue"),
        props: true
      },
      { 
        path: "/user_profile_page/:id",
        name: "UserProfilePage",
        meta: {
            middleware: auth
        },
        component: () => import("../components/backoffice/details-page/UserProfilePage.vue"),
        props: true
      },
      { 
        path: "/password_change",
        name: "PasswordChange",
        meta: {
            middleware: auth
        },
        component: () => import("../components/backoffice/details-page/PasswordChange.vue"),
        props: true
      },
      
      {
        path: "/user_profile_page/:id",
        name: "UserProfilePage",
        meta: {
            middleware: auth
        },
        component: () => import("../components/backoffice/details-page/UserProfilePage.vue"),
        props: true,
        children: [
          {
            alias: '',
            path: "/user_edit_profile_page/:id",
            name: "UserEditProfilePage",
            meta: {
                middleware: auth
            },
            component: () =>
              import("../components/backoffice/details-page/UserEditProfilePage.vue"),
              props: true,
          },
          { 
            path: "/payment_methods_page/:id",
            name: "PaymentMethodsPage",
            meta: {
                middleware: auth
            },
            component: () => import("../components/backoffice/details-page/PaymentMethodsPage.vue"),
            props: true
          },
          { 
            path: "/faturalar_page/:id",
            name: "FaturaPage",
            meta: {
                middleware: auth
            },
            component: () => import("../components/backoffice/details-page/FaturaPage.vue"),
            props: true
          },
          { 
            path: "/siparisler_detail/:id",
            name: "SiparislerDetail",
            meta: {
                middleware: auth
            },
            component: () => import("../components/backoffice/details-page/SiparislerDetail.vue"),
            props: true
          }
        ],
      },

      //
      // //Old Theme Router
      // {
      //   path: "/dashboard",
      //   name: "dashboard",
      //   component: () => import("@/views/Dashboard.vue"),
      // },
      // {
      //   path: "/builder",
      //   name: "builder",
      //   component: () => import("@/views/Builder.vue"),
      // },
      // {
      //   path: "/crafted/pages/profile",
      //   name: "profile",
      //   component: () => import("@/components/page-layouts/Profile.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "profile-overview",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Overview.vue"),
      //     },
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Projects.vue"),
      //     },
      //     {
      //       path: "campaigns",
      //       name: "profile-campaigns",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Campaigns.vue"),
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Documents.vue"),
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Connections.vue"),
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Activity.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/crafted/pages/wizards/horizontal",
      //   name: "horizontal-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      // },
      // {
      //   path: "/crafted/pages/wizards/vertical",
      //   name: "vertical-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      // },
      // {
      //   path: "/crafted/account",
      //   name: "account",
      //   component: () => import("@/views/crafted/account/Account.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "account-overview",
      //       component: () => import("@/views/crafted/account/Overview.vue"),
      //     },
      //     {
      //       path: "settings",
      //       name: "account-settings",
      //       component: () => import("@/views/crafted/account/Settings.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/apps/customers/getting-started",
      //   name: "apps-customers-getting-started",
      //   component: () => import("@/views/apps/customers/GettingStarted.vue"),
      // },
      // {
      //   path: "/apps/customers/customers-listing",
      //   name: "apps-customers-listing",
      //   component: () => import("@/views/apps/customers/CustomersListing.vue"),
      // },
      // {
      //   path: "/apps/customers/customer-details",
      //   name: "apps-customers-details",
      //   component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      // },
      // {
      //   path: "/apps/chat/private-chat",
      //   name: "apps-private-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/group-chat",
      //   name: "apps-group-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/drawer-chat",
      //   name: "apps-drawer-chat",
      //   component: () => import("@/views/apps/chat/DrawerChat.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/invite-friends",
      //   name: "modals-general-invite-friends",
      //   component: () =>
      //     import("@/views/crafted/modals/general/InviteFriends.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/view-user",
      //   name: "modals-general-view-user",
      //   component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/upgrade-plan",
      //   name: "modals-general-upgrade-plan",
      //   component: () =>
      //     import("@/views/crafted/modals/general/UpgradePlan.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/share-and-earn",
      //   name: "modals-general-share-and-earn",
      //   component: () =>
      //     import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-app",
      //   name: "modals-wizards-create-app",
      //   component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-account",
      //   name: "modals-wizards-create-account",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      // },
      // {
      //   path: "/crafted/widgets/lists",
      //   name: "widgets-list",
      //   component: () => import("@/views/crafted/widgets/Lists.vue"),
      // },
      // {
      //   path: "/crafted/widgets/statistics",
      //   name: "widgets-statistics",
      //   component: () => import("@/views/crafted/widgets/Statistics.vue"),
      // },
      // {
      //   path: "/crafted/widgets/charts",
      //   name: "widgets-charts",
      //   component: () => import("@/views/crafted/widgets/Charts.vue"),
      // },
      // {
      //   path: "/crafted/widgets/mixed",
      //   name: "widgets-mixed",
      //   component: () => import("@/views/crafted/widgets/Mixed.vue"),
      // },
      // {
      //   path: "/crafted/widgets/tables",
      //   name: "widgets-tables",
      //   component: () => import("@/views/crafted/widgets/Tables.vue"),
      // },
      // {
      //   path: "/crafted/widgets/feeds",
      //   name: "widgets-feeds",
      //   component: () => import("@/views/crafted/widgets/Feeds.vue"),
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        meta: {
            middleware: guest
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        meta: {
            middleware: guest
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/logout",
        name: "logout",
        component: {
          beforeRouteEnter() {
            store.dispatch(Actions.LOGOUT).then(() => {
              // on logout redirect to main site.
              if (store.getters.getParameterList.use_backoffice_on_subdomain) {
                location.href = base_url;
                return false;
              }
              router.push({name: "sign-in"});
            });
            return false;
          }
        }
      },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: {
            middleware: guest
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

  router.beforeEach(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  });

router.beforeEach(VueRouteMiddleware());

router.beforeEach(async () => {
  document.title = 'yükleniyor...';

  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const token = params._token;

  if (token) {
    JwtService.saveToken(token);
    await store.dispatch(Actions.VERIFY_AUTH).then(() => router.push({name: 'ozet'}));
  }

  store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

  if (!Object.keys(store.getters.getGeneralSettingsList).length) {
    await store.dispatch(Actions.SET_GENERALSETTINGS_LIST_ACTION);
  }

  if (!Object.keys(store.getters.getParameterList).length) {
    await store.dispatch(Actions.SET_PARAMETERS_LIST_ACTION);
  }

  if (!Object.keys(store.getters.getLangList.data).length) {
    await store.dispatch(Actions.SET_LANG_LIST_ACTION);
  }

  document.title = store.getters.getTitle;
});

export default router;
