import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {api_url} from "@/ayar";

export interface GeneralSettings {
  add_cart: string;
  add_compare: string;
  add_wish: string;
  address: string;
  admin_loader: string;
  affilate_banner: string;
  affilate_charge: number
  already_cart: string
  already_compare: string
  already_coupon: string
  already_wish: string
  american_express: number
  app_domain: string
  block_days: number
  color_change: string
  colors: string
  compare_remove: string
  copyright: string
  copyright_color: string
  copyright_fc: string
  copyright_lc: string
  copyright_lhc: string
  coupon_found: string
  created_at: string
  currency_format: number
  db_prefix: string
  deleted_at: string
  demo: number
  disqus: string
  domain: string
  email: string
  email_encryption: string
  error_banner: string
  etbis: string
  favicon: string
  fixed_commission: number
  footer: string
  footer_bhc: string
  footer_bhfc: string
  footer_color: string
  footer_fc: string
  footer_lc: string
  footer_lhc: string
  footer_logo: string
  from_email: string
  from_name: string
  garanti_logo: number
  gsm: string
  guest_checkout: number
  havale_txt: string
  header_bc: string
  header_bfc: string
  header_bhc: string
  header_bhfc: string
  header_color: string
  header_email: string
  header_fc: string
  header_phone: string
  hesaplamaya_girecek_mi: number
  id: number
  installation_confirmation: string
  instamojo_key: string
  instamojo_sandbox: number
  instamojo_token: string
  invoice_logo: string
  is_admin_loader: number
  is_affilate: number
  is_capcha: number
  is_comment: number
  is_contact: number
  is_currency: number
  is_disqus: number
  is_etbis: number
  is_faq: number
  is_havale: number
  is_home: number
  is_language: number
  is_loader: number
  is_maintain: number
  is_popup: number
  is_report: number
  is_secure: number
  is_smtp: number
  is_talkto: number
  is_verification_email: number
  iyzico_logo: number
  kdv_dahil: boolean
  kisa_kod: string
  kredi_karti_aktif: boolean
  loader: string
  logo: string
  logo_url: string
  main: number
  maintain_text: string
  map_key: string
  mastercard_logo: number
  menu_color: string
  menu_hover_color: string
  modules: string
  molly_key: string
  multiple_packaging: number
  multiple_shipping: number
  name: string
  network_admin: number
  no_coupon: string
  order_text: string
  order_title: string
  out_stock: string
  owner_name: string
  paypal_business: string
  paystack_email: string
  paystack_key: string
  paytm_industry: string
  paytm_merchant: string
  paytm_mode: string
  paytm_secret: string
  paytm_website: string
  percentage_commission: number
  phone: string
  platform_id: number
  platform_package_id: number
  popup_background: string
  popup_text: string
  popup_title: string
  razorpay_key: string
  razorpay_secret: string
  reg_vendor: number
  service_end_date: string
  service_first_payment_date: string
  setup: number
  shipping_cost: number
  show_stock: number
  sms_baslik: string
  sms_kullanici: string
  sms_sifre: string
  smtp_host: string
  smtp_pass: string
  smtp_port: string
  smtp_user: string
  source: number
  ssl_logo: number
  status: number
  stripe_key: string
  stripe_secret: string
  talkto: string
  tax: number
  title: string
  topbar_bc: string
  topbar_bfc: string
  topbar_bhc: string
  topbar_bhfc: string
  topbar_color: string
  topbar_fc: string
  updated_at: string
  user_image: string
  vendor_color: string
  vendor_ship_info: number
  visa_logo: number
  warning_days: number
  wholesell: number
  wish_remove: string
  withdraw_charge: number
  withdraw_fee: number
}

export interface GeneralSettingsInterface {
  generalSettings: GeneralSettings;
}

@Module
export default class GeneralSettingsModule extends VuexModule  {
  generalSettings = {} as GeneralSettings;

  get getGeneralSettingsList() : GeneralSettings{
    return this.generalSettings;
  }

  get getTitle():string{
    return this.generalSettings.title
  }

  get getFavicon():string{
    return this.generalSettings.favicon
  }

  @Mutation
  [Mutations.SET_GENERAL_SETTINGS_LIST](settings) {
    this.generalSettings = settings;
  }

  @Action
  [Actions.SET_GENERALSETTINGS_LIST_ACTION](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(api_url+"generalSettings", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_SETTINGS_LIST, data);
          resolve();
        })
    });
  }

}
