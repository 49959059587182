import $ from "jquery";

export default () => {
        /*-------------------------------------
        Background image
        -------------------------------------*/
        $("[data-bg-image]").each(function () {
            var img = $(this).data("bg-image");
            $(this).css({
                backgroundImage: "url(" + img + ")"
            });
        });

        /*-------------------------------------
        After Load All Content Add a Class
        -------------------------------------*/
        $('.fxt-template-animation').addClass('loaded');
        /*-------------------------------------
        Toggle Class
        -------------------------------------*/
        $(".toggle-password").on('click', function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });


        /*-------------------------------------
        OTP Form (Focusing on next input)
        -------------------------------------*/
        $("#otp-form .otp-input").keyup(function () {
            if (this.value.length == this.maxLength) {
                $(this).next('.otp-input').focus();
            }
        });

        /*-------------------------------------
        Social Animation
        -------------------------------------*/
        $('#fxt-login-option >ul >li').hover(function () {
            $('#fxt-login-option >ul >li').removeClass('active');
            $(this).addClass('active');
        });

        /*-------------------------------------
        Preloader
        -------------------------------------*/
        $('#preloader').fadeOut('slow', function () {
            $(this).remove();
        });

}