import {Action, Mutation, Module, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";

interface DateFilter {
    start: string;
    end: string;
}

interface StoreInfo {
    date: DateFilter;
}

@Module
export default class DateFilterModule extends VuexModule implements StoreInfo {
    date = {} as DateFilter;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get getDate(): DateFilter {
        return this.date;
    }

    /**
     * Get current date query
     * @returns string
     */
    get dateQuery(): DateFilter {
        return this.date;
    }

    @Mutation
    [Mutations.SET_DATE_MUTATION](payload) {
        this.date = payload;
    }

    @Action
    [Actions.SET_DATE_ACTION](payload) {
        this.context.commit(Mutations.SET_DATE_MUTATION, payload);
    }
}
