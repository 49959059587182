import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";
import store from "@/store";

export interface ApiInterface {
    response: Array<string>;
}

@Module
export default class ApiModule extends VuexModule {
    response = []

    @Mutation
    [Mutations.RESPONSE_DATA](data) {
        this.response = data;
    }

    /*@Action
    [Actions.MY_ORDERS]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("myOrders")
                .then(({data}) => {
                    store.commit('setOrders',data)
                })
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }

    }*/


}
