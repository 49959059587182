enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  PURGE_AUTH = "purgeAuth",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  GET_USER = "getUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_SEARCH_ACTION = 'setSearchAction',
  SET_DATE_ACTION = 'setDateAction',
  SET_EARNING_LIST_ACTION = "setEarningListAction",
  SET_GENERALSETTINGS_LIST_ACTION = "setGeneralsettingsListAction",
  SET_PARAMETERS_LIST_ACTION = "setParametersListAction",
  SET_LANG_LIST_ACTION = "setLangListAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_CART = "setCart",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_SEARCH_MUTATION = 'setSearchMutation',
  SET_DATE_MUTATION = 'setDateMutation',
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  RESPONSE_DATA = "responseData",
  SET_EARNING_LIST = "setEarningList",
  SET_GENERAL_SETTINGS_LIST = "setGeneralSettingList",
  SET_PARAMETERS_LIST = "setParametersList",
  SET_LANG_LIST = "setLangList",
  SET_INFO = "setInfo"
}

export { Actions, Mutations };
