import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Search {
  query: string;
}

interface StoreInfo {
  search: Search;
}

@Module
export default class SearchModule extends VuexModule implements StoreInfo {
  search = {} as Search;

  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get getSearch(): Search {
    return this.search;
  }

  /**
   * Get current search query
   * @returns string
   */
  get searchQuery(): string {
    return this.search.query;
  }

  @Mutation
  [Mutations.SET_SEARCH_MUTATION](payload) {
    this.search = payload;
  }

  @Action
  [Actions.SET_SEARCH_ACTION](payload) {
    this.context.commit(Mutations.SET_SEARCH_MUTATION, payload);
  }
}
