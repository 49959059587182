import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {api_url} from "@/ayar";
import {reactive, ref} from "vue";

export interface Lang {
  value: string;
}

@Module
export default class LangModule extends VuexModule  {
  lang = reactive({
    lang: ref(''),
    data: reactive({})
  });

  get getLangList() {
    return this.lang;
  }

  @Mutation
  [Mutations.SET_LANG_LIST](lang) {
    this.lang = lang;
  }

  @Action
  [Actions.SET_LANG_LIST_ACTION](data) {
    return new Promise<void>((resolve) => {
      ApiService.post(api_url+'lang', data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LANG_LIST, data);
          resolve();
        })
    });
  }

}
