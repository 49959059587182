
import {defineComponent, onMounted, computed, onUpdated} from "vue";
import { useStore } from "vuex";
import {base_url} from "@/ayar";
import loginMainJs from "./assets/loginTemplate/js/main"

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const generalSettings = computed(() => store.getters.getGeneralSettingsList);

    const updateFavIcon = () => {
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.setAttribute("href", base_url + 'assets/images/' + generalSettings.value.favicon);
      }
    }

    onUpdated(updateFavIcon)
    onMounted(() => {
      updateFavIcon();

      loginMainJs();
    });

    return {
      generalSettings,
    }
  },
});
