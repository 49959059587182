import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {api_url} from "@/ayar";

export interface Earning {
  value: string;
}

export interface EarningInterface {
  earnings: Array<string>;
}

@Module
export default class EarningsModule extends VuexModule  {
  earnings = [];

  get getEarningList() {
    return this.earnings;
  }

  @Mutation
  [Mutations.SET_EARNING_LIST](earnings) {
    this.earnings = earnings;
  }

  @Action
  [Actions.SET_EARNING_LIST_ACTION](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(api_url+"earningList", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EARNING_LIST, data);
          resolve();
        })
        // .catch(({ response }) => {
        //   this.context.commit(Mutations.SET_ERROR, response.data.errors);
        //   reject();
        // });
    });
  }

}
