import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {api_url} from "@/ayar";
import store from "@/store";
export interface Cart{
  items : any;
  totalPriceWithoutTax: number;
  totalTax: number;
  totalDiscount:number;
  totalHamPrice:number;
  totalPrice:number;
  totalQty:number;
  userGeneralDiscount:number;
}
export interface User {
  name: string;
  surname: string;
  email: string;
  platform_id: number;
  sponsor_id: number;
  packetName: string;
  careerName: string;
  information: number;
  token: string;
  carts:  Cart;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get getSponsorId():number {
    return this.user.sponsor_id;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](response) {
    this.isAuthenticated = true;
    this.user = response.data;
    this.errors = [];
    JwtService.saveToken(this.user.token);
    window.localStorage.setItem("USER_OBJ", response.data);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    window.localStorage.setItem("USER_OBJ", user);
  }
  @Mutation
  [Mutations.SET_CART](carts) {
    this.user.carts = carts;
  }

  @Mutation
  [Mutations.SET_INFO]() {
    this.user.information = 1;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    return new Promise<void>((resolve) => {
      this.isAuthenticated = false;
      this.user = {carts:{items:[],totalQty:0}} as User;
      this.errors = [];
      JwtService.destroyToken();
      window.localStorage.removeItem("USER_OBJ");
      resolve();
    });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.PURGE_AUTH]() {
    return new Promise<void>((resolve) => {
      this.context.commit(Mutations.PURGE_AUTH);
      resolve();
    })
  }

  @Action
  [Actions.LOGOUT]() {
    return new Promise<void>((resolve) => {
      ApiService.post("logout", {})
      this.context.commit(Mutations.PURGE_AUTH);

      resolve();
    })
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.get("verify")
            .then(({data}) => {
              this.context.commit(Mutations.SET_AUTH, data);
               resolve();
            })
            .catch(({response}) => {
              this.context.commit(Mutations.SET_ERROR, response.data.errors);
              reject();
            });
      });
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(api_url + "user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data.mesaj);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
